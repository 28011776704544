<template>
    <div class="history__container">
        <h1 class="history__title">История изменений</h1>

        <div class="history__content-wrapper">
            <select class="history__select" v-model="type">
                <option value="">Все</option>
                <option value="clientPayment">Платежи</option>
            </select>

            <div class="history__table-wrapper" v-if="items.length">

                <table
                    class="history__table">
                    <thead class="history__table-head">
                    <th class="history__head-item" @click="sort('id')">№
                        <span class="inline-block mx-1" :class="{
                            'visible':sorting.sort !== '' && sorting.by === 'id',
                            'visible transform  rotate-0':sorting.sort === 'desc' && sorting.by === 'id',
                            'visible transform   rotate-180':sorting.sort === 'asc' && sorting.by === 'id',
                            'hidden':sorting.by !== 'id'
                        }"><i class="fas fa-arrow-down "></i></span>
                    </th>
                    <th class="history__head-item" v-for="header in headers" :key="header.value"
                        @click="sort(header.value)">{{ header.name }}
                        <span class="inline-block  mx-1" :class="{
                            'visible':sorting.sort !== '' && sorting.by === header.value,
                            'visible transform rotate-0':sorting.sort === 'desc' && sorting.by === header.value,
                            'visible transform rotate-180':sorting.sort === 'asc' && sorting.by === header.value,
                            'hidden':sorting.by !== header.value
                        }"><i class="fas fa-arrow-down "></i></span>
                    </th>


                    </thead>
                    <tbody class="history__table-body">
                    <tr class="history__table-row"
                        v-for="(history, idx) in filteredItems" :key="history.id"
                    >
                        <td class="history__table-row-item">{{ idx + 1 }}</td>
                        <td class="history__table-row-item " :key="header.value" v-for="header in headers">{{ history[header.value] }}</td>

                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <Pagination :hideNext="hideNext" dashboard namespace="history" v-if="items.length"></Pagination>
    </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import TableSortingMixin from '@/mixins/tableSorting'

import {mapGetters} from "vuex";

export default {
    name: 'History',
    meta: {
        title: 'История'
    },
    components: {Pagination},
    data: () => ({
        type: "",
        headers: [
            {name: 'Пользователь', value: 'uf_user'},
            {name: 'Сущность', value: 'uf_entity_type'},
            {name: 'Данные', value: 'uf_entity_data'},
            {name: 'Дата', value: 'uf_timestamp'},
            {name: 'Контроллер', value: 'uf_controller'},
        ],
        filter: {},
    }),
    created() {
        this.$store.dispatch('history/fetch')
    },
    beforeDestroy() {
        document.removeEventListener('keypress', this.pressEnter)
        this.$store.commit('history/unset')
    },
    mounted() {
        document.addEventListener('keypress', this.pressEnter)
        this.$watch('sorting', function (newVal) {
            this.$store.commit('history/setSorting', {sort: newVal.sort, sortBy: newVal.by})
        }, {
            deep: true, immediate: true
        })
    },
    computed: {
        ...mapGetters({
            items: 'history/items',
            perPage: 'history/perPage'
        }),
        hideNext() {
            return this.items.length === 0 || this.items.length < this.perPage;
        },
        filteredItems() {
            if (this.type !== '') {
                return this.items.filter(i => i.uf_entity_type === this.type)
            }
            return this.items
        }
    },
    methods: {
        pressEnter() {
            if (event.keyCode === 13) {
                this.filterItems()
            }
        },
        clear() {
            for (let i in this.form) {
                this.form[i] = ''
            }
            this.$v.$reset()
        },
        clearFilter() {
            for (let i in this.filter) {
                this.filter[i] = ''
            }
        },
        filterItems() {
            this.$store.commit('clientCars/setFilter', this.filter)
        },
        dropFilter() {
            this.$store.commit('clientCars/unset')
            if (this.$route.params.user) {
                this.$store.commit('clientCars/selectUser', this.user.id)
            }
            this.$store.dispatch('clientCars/getVehicles')
            this.clearFilter()
        },
    },
    mixins: [TableSortingMixin],

}
</script>

<style scoped>
.history__container {
    @apply my-10 mx-auto
    sm:px-2
    lg:px-5
    xl:container
}

.history__title {
    @apply dark:text-white text-gray-800 text-2xl
}

.history__content-wrapper {
    @apply w-full mx-auto my-10
    xl:container
}

.history__table-wrapper {
    @apply my-10 max-h-128 overflow-y-scroll
}

.history__table {
    @apply text-left w-full border-collapse  dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm
}

.history__table-head {
    @apply dark:bg-gray-800 bg-white text-gray-500
}
.history__head-item{
    @apply p-3 select-none
}
.history__select {
    @apply
    text-black placeholder-gray-600 w-full px-4 py-2.5  text-base  dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent
    rounded bg-gray-200  focus:border-gray-500 dark:bg-gray-800
    dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
    dark:focus:text-white
    sm:w-full
    md:w-1/2
    xl:w-1/3;
}
.history__table-body{
    @apply  dark:text-gray-400 text-gray-600
}
.history__table-row{
    @apply dark:bg-gray-800 bg-white dark:hover:bg-gray-700 hover:bg-gray-300
}
.history__table-row-item{
    @apply p-3
}
::-webkit-scrollbar {
    display: block;
    overflow: auto;
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: lightskyblue;
}
</style>