var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history__container"},[_c('h1',{staticClass:"history__title"},[_vm._v("История изменений")]),_c('div',{staticClass:"history__content-wrapper"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.type),expression:"type"}],staticClass:"history__select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.type=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("Все")]),_c('option',{attrs:{"value":"clientPayment"}},[_vm._v("Платежи")])]),(_vm.items.length)?_c('div',{staticClass:"history__table-wrapper"},[_c('table',{staticClass:"history__table"},[_c('thead',{staticClass:"history__table-head"},[_c('th',{staticClass:"history__head-item",on:{"click":function($event){return _vm.sort('id')}}},[_vm._v("№ "),_c('span',{staticClass:"inline-block mx-1",class:{
                        'visible':_vm.sorting.sort !== '' && _vm.sorting.by === 'id',
                        'visible transform  rotate-0':_vm.sorting.sort === 'desc' && _vm.sorting.by === 'id',
                        'visible transform   rotate-180':_vm.sorting.sort === 'asc' && _vm.sorting.by === 'id',
                        'hidden':_vm.sorting.by !== 'id'
                    }},[_c('i',{staticClass:"fas fa-arrow-down "})])]),_vm._l((_vm.headers),function(header){return _c('th',{key:header.value,staticClass:"history__head-item",on:{"click":function($event){return _vm.sort(header.value)}}},[_vm._v(_vm._s(header.name)+" "),_c('span',{staticClass:"inline-block  mx-1",class:{
                        'visible':_vm.sorting.sort !== '' && _vm.sorting.by === header.value,
                        'visible transform rotate-0':_vm.sorting.sort === 'desc' && _vm.sorting.by === header.value,
                        'visible transform rotate-180':_vm.sorting.sort === 'asc' && _vm.sorting.by === header.value,
                        'hidden':_vm.sorting.by !== header.value
                    }},[_c('i',{staticClass:"fas fa-arrow-down "})])])})],2),_c('tbody',{staticClass:"history__table-body"},_vm._l((_vm.filteredItems),function(history,idx){return _c('tr',{key:history.id,staticClass:"history__table-row"},[_c('td',{staticClass:"history__table-row-item"},[_vm._v(_vm._s(idx + 1))]),_vm._l((_vm.headers),function(header){return _c('td',{key:header.value,staticClass:"history__table-row-item "},[_vm._v(_vm._s(history[header.value]))])})],2)}),0)])]):_vm._e()]),(_vm.items.length)?_c('Pagination',{attrs:{"hideNext":_vm.hideNext,"dashboard":"","namespace":"history"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }